<template>
  <div class="col-12 g c col-lg-4">
    <br><br>
    <div class="card card-body">
        <img :src="require('@/assets/images/banner.png')" style="width: 50%; margin: 0px auto; padding: 20px" alt="">
        <div class="alert alert-success g text-dark text-center">
            <h4>تم ارسال كود التفعيل على الرقم {{ user.phone }} على الواتساب.</h4>
        </div>
        <div class="alert alert-danger text-center g" v-if="error">
            {{ error }}
        </div>
        <div class="form-group g">
          <h5 for="">كود التفعيل</h5>
          <input type="text"
            class="form-control" v-model="code">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="login()">
                تفعيل الحساب
            </button>
        </div>
        <br>
        <span>
            الجوال غير صحيح؟
            <a href="javascript:;" @click="$router.push('/logout')">
                <i class="fa fa-sign-out"></i>
                تسجيل الخروج
            </a>
        </span>
        <br>
        اذا لم يصلك كود التفعيل برجاء التواصل مع الدعم الفني.
    </div>
  </div>
</template>

<script>
export default {
    created(){

    },
    data(){
        return {
            code: "",
            user: JSON.parse(localStorage.getItem("user")),
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            error: ""
        }
    },
    methods: {
        login(){
            var g = this;
            g.error = null;
            $.post(api + '/user/auth/activate', {
                jwt: this.jwt,
                code: this.code
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    localStorage.setItem("user", JSON.stringify(r.response))
                    window.location = '/'
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        }
    }
}
</script>

<style>

</style>